<template>
    <div>
        <!-- 분석서비스 이용 동의 팝업 -->
        <div class="popup__wrap recom-announce__pop--wrap consent-use__pop--wrap" :class="isPopCustCntrct ? 'active' : ''">

            <!-- 내용2 -->
            <div class="popup__item--container recom-announce__pop--container consent-use__pop--container step-01" :class="isPopCustCntrctStep2_1 ? 'active' : ''">
                <div class="popup__item--group recom-announce__pop--group consent-use__pop--group">
                    <div class="popup__item--head">
                        <p class="txt__title">AI멤버십 서비스 이용 동의</p>
                        <a href="javascript:void(0)" class="popup__close" @click="isPopCustCntrct = false; isPopCustCntrctStep2_1 = false;">닫기버튼</a>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body consent-use__pop--body">
                        <p class="txt__title border-bottom">① AI멤버십 서비스 이용 동의</p>
                        <div class="join-form__box">
                            <p class="join-form__title consent-title">⑤ 약관동의</p>
                            <ul class="input join-form__wrap--link consent-use__pop--link">
                                <li class="join-form__wrap--move use-consent">
                                    <a href="javascript:void(0)" class="btn-basic btn-line-cw link__consent" @click="clkSubscription()">(멤버십) AI구독서비스 제공계약서 {{$parent.selectedPlanId == '2' ? '(3개월)' : $parent.selectedPlanId == '3' ? '(6개월)' : $parent.selectedPlanId == '5' ? '(12개월)' : ''}}
                                        <span class="link__consent--check">
                                            <i class="check-none" title="체크 비활성화" :style="{ display: !subscriptionYn ? 'inline-block' : 'none' }"></i>
                                            <i class="check-active" title="체크 활성화" :style="{ display: subscriptionYn ? 'inline-block' : 'none' }"></i>
                                            동의
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="javascript:void(0)" class="btn-basic btn-cta popup__next"
                       :class="subscriptionYn ? 'btn__active':'btn__non-active'"
                       @click="insertAISubAgree()">
                        완료
                    </a>
                </div>
            </div>
            <!-- / 내용2 -->

            <!-- 마지막 -->
<!--            <div class="popup__item&#45;&#45;container recom-announce__pop&#45;&#45;container consent-use__pop&#45;&#45;container step-last" :class="isPopCustCntrctStep3 ? 'active' : ''">-->
<!--                <div class="popup__item&#45;&#45;group recom-announce__pop&#45;&#45;group consent-use__pop&#45;&#45;group">-->
<!--                    <div class="popup__item&#45;&#45;head">-->
<!--                        <p class="txt__title">분석서비스 이용 동의2</p>-->
<!--                        <a href="javascript:void(0)" class="popup__close" @click="closeCustCntrctPop();">닫기버튼</a>-->
<!--                    </div>-->
<!--                    <div class="popup__item&#45;&#45;body recom-announce__pop&#45;&#45;body consent-use__pop&#45;&#45;body">-->
<!--                        <p class="txt__title">분석서비스 이용 동의가<br>완료되었습니다.</p>-->
<!--                        <div class="grid__wrap">-->
<!--                            <div class="grid__container consent-use__pop&#45;&#45;grid">-->
<!--                                <ul class="grid__group head">-->
<!--                                    <li class="grid__cell consent-use__pop&#45;&#45;grid&#45;&#45;head">동의날짜</li>-->
<!--                                    <li class="grid__cell consent-use__pop&#45;&#45;grid&#45;&#45;body">{{agreeDt}}</li>-->

<!--                                </ul>-->
<!--                                <ul class="grid__group head">-->
<!--                                    <li class="grid__cell consent-use__pop&#45;&#45;grid&#45;&#45;head">기간</li>-->
<!--                                    <li class="grid__cell consent-use__pop&#45;&#45;grid&#45;&#45;body">{{agreeDate}}(1년)</li>-->
<!--                                </ul>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        &lt;!&ndash;                        <p class="txt__sub" v-show="email != '' && checkEmail">분석서비스 제공계약서는 이메일로 발송되었습니다.</p>&ndash;&gt;-->
<!--                        <p class="txt__sub">멤버십 혜택 받아가세요!</p>-->
<!--                        <a class="ai-mbr&#45;&#45;promo"></a>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="popup__button&#45;&#45;wrap single">-->
<!--                    <a href="javascript:void(0)" class="btn-basic btn-cta popup__close" @click="closeCustCntrctPop(); $router.go(0);">확인</a>-->
<!--                </div>-->
<!--            </div>-->
            <!-- /마지막 -->

            <!-- 마지막2 -->
            <div class="popup__item--container recom-announce__pop--container consent-use__pop--container step-last" :class="isPopCustCntrctStep3_1 ? 'active' : ''">
                <div class="popup__item--group recom-announce__pop--group consent-use__pop--group">
                    <div class="popup__item--head">
                        <p class="txt__title">AI멤버십 서비스 이용 동의</p>
                        <a href="javascript:void(0)" class="popup__close" @click="isPopCustCntrct = false; isPopCustCntrctStep3_1 = false; initAiSub(0);">닫기버튼</a>
                    </div>
                    <div class="popup__item--body recom-announce__pop--body consent-use__pop--body">
                        <p class="txt__title">AI멤버십 서비스 이용<br>이용 동의 및 신청이 완료되었습니다.</p>
                        <div class="grid__wrap">
<!--                            <div class="grid__container consent-use__pop&#45;&#45;grid" v-if="!$parent.isTestId">-->
<!--                                <ul class="grid__group head">-->
<!--                                    <li class="grid__cell consent-use__pop&#45;&#45;grid&#45;&#45;head">은행명 / 예금주명</li>-->
<!--                                    <li class="grid__cell consent-use__pop&#45;&#45;grid&#45;&#45;body">KB국민은행 / 주식회사 비드톡톡</li>-->
<!--                                </ul>-->
<!--                                <ul class="grid__group head">-->
<!--                                    <li class="grid__cell consent-use__pop&#45;&#45;grid&#45;&#45;head">계좌번호</li>-->
<!--                                    <li class="grid__cell consent-use__pop&#45;&#45;grid&#45;&#45;body">740901-01-693861</li>-->
<!--                                </ul>-->
<!--                                <ul class="grid__group head">-->
<!--                                    <li class="grid__cell consent-use__pop&#45;&#45;grid&#45;&#45;head">요금</li>-->
<!--                                    <li class="grid__cell consent-use__pop&#45;&#45;grid&#45;&#45;body">{{this.$parent.loginInfo.planNm}} {{Number(this.$parent.loginInfo.planPrice).toLocaleString()}}원</li>-->
<!--                                </ul>-->
<!--                            </div>-->
                            <div class="grid__container consent-use__pop--grid">
                                <ul class="grid__group head">
                                    <li class="grid__cell consent-use__pop--grid--head">상품명</li>
                                    <li class="grid__cell consent-use__pop--grid--body">AI멤버십 {{this.$parent.loginInfo.planNm}}</li>
                                </ul>
                                <ul class="grid__group head">
                                    <li class="grid__cell consent-use__pop--grid--head">요금</li>
                                    <li class="grid__cell consent-use__pop--grid--body">{{Number(this.$parent.loginInfo.planPrice).toLocaleString()}}원</li>
                                </ul>
                            </div>
                        </div>
                        <div class="popup__item--body" style="margin-top: -30px;margin-bottom: 15px">
                            <div class="ai-membership--announce">
<!--                                <p class="txt__sub" style="font-weight: bold;" v-if="!$parent.isTestId">입금자명은 가입 시의 <span style="text-decoration:underline">기업명</span>으로 입금해 주세요.<br/><br/></p>-->
                                <p class="txt__sub" style="font-weight: bold;">하단 [결제하기] 버튼을 클릭하여 가상계좌를 발급해 멤버십 이용 금액 납부 부탁드립니다.<br/><br/></p>
                                <p class="txt__sub">
                                    AI멤버십은 입금 확인 후부터 사용하실 수 있습니다.<br>
                                    기타 문의사항은 고객센터 1670-0508로 연락 주세요.<br><br>
                                    ※ 본 계약에 따라 서비스 이용 금액은 자동 결제되지 않으며, 고객은 매월 수동으로 결제해야 합니다.
                                </p>
                            </div>
                        </div>

                        <!--                        <p class="txt__sub" v-show="email != '' && checkEmail">분석서비스 제공계약서는 이메일로 발송되었습니다.</p>-->
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="javascript:void(0)" class="btn-basic btn-cta popup__close" @click="isPopCustCntrct = false; initAiSub(1);" v-if="$parent.isTestId">결제하기</a>
                    <a href="javascript:void(0)" class="btn-basic btn-cta popup__close" @click="isPopCustCntrct = false;" v-if="!$parent.isTestId">확인</a>
                </div>
            </div>
            <!-- /마지막2 -->
        </div>

        <!-- AI멤버십 서비스 동의 계약서 -->
        <div class="popup__wrap use-consent__wrap Provis" :class="isPopSubscription ? 'active' : ''">
            <div class="popup__item--container use-consent__container">
                <div class="popup__item--group use-consent__wrap--group">
                    <div class="popup__item--head">
                        <h3 class="txt__title">AI멤버십 서비스 동의 계약서</h3>
                        <button type="button" class="popup__close" @click="closeCustSubCntrctPop()"></button>
                    </div>
                    <div id="subscriptionScrollComn" class="popup__item--body use-consent__wrap--body">
                        <div class="clause wrap agree">
                            <div class="agree__table--wrap">
                                <ul class="agree__table--group">
                                    <li class="agree__table--item subject"><span class="agree__txt">계약자</span></li>
                                    <li class="agree__table--item content">
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">정보 제공자
                                                (“동”) </span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub"><span>회 사 명</span><span class="agree__table--title">
                                                    주식회사 비드톡톡</span></p>
                                                <p class="agree__txt agree__table--sub"><span>대 표 이 사</span><span class="agree__table--title">
                                                    최경호</span></p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">정보 이용자
                                                (“행”) </span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub"><span>회 사 명</span><span id="cmpNm2"
                                                                                                                class="agree__table--title"><strong>{{$parent.custBiz.cmpNm}}</strong></span></p>
                                                <p class="agree__txt agree__table--sub"><span>대 표 이 사</span><span id="userNm2"
                                                                                                                  class="agree__table--title"><strong>{{$parent.custBiz.rpsntvNm}}</strong></span></p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul class="agree__table--group">
                                    <li class="agree__table--item subject"><span class="agree__txt">계약내용</span></li>
                                    <li class="agree__table--item content">
                                        <div class="agree__table--box couple">
                                            <div class="agree__table--element head"><span class="agree__txt agree__txt--head">계약명</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub"><span>AI멤버십 서비스 이용 계약 {{$parent.selectedPlanId == '2' ? '(3개월)' : $parent.selectedPlanId == '3' ? '(6개월)' : $parent.selectedPlanId == '5' ? '(12개월)' : ''}}</span></p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box couple">
                                            <div class="agree__table--element head"><span class="agree__txt agree__txt--head">계약 금액</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub">금<span
                                                        class="agree__table--title"><strong>{{$parent.selectedPlanId == '2' ? '399,000' : $parent.selectedPlanId == '3' ? '599,000' : $parent.selectedPlanId == '5' ? '990,000' : ''}}</strong></span>원 (VAT포함) </p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box couple">
                                            <div class="agree__table--element head"><span class="agree__txt agree__txt--head">성공 보수료</span>
                                            </div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">※ 아래 <span>&lt;표1&gt;</span>에서 제시하는
                                                    바에 따름</p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">성공 보수료 지급
                                                조건</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">* 분석기업 경우 낙찰일로부터 14일 이내</p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">계약
                                                기간</span></div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">이용 대금이 납부된 날로부터 시작하며, {{$parent.selectedPlanId == '2' ? '3개월' : $parent.selectedPlanId == '3' ? '6개월' : $parent.selectedPlanId == '5' ? '12개월' : ''}}간 이용 가능함. (*1개월이라고 함은 30일로 계산)</p>
                                            </div>
                                        </div>
                                        <div class="agree__table--box">
                                            <div class="agree__table--element head couple"><span class="agree__txt agree__txt--head">기타</span>
                                            </div>
                                            <div class="agree__table--element body">
                                                <p class="agree__txt agree__table--sub agree__table--title">※ 별첨 계약 내용에 정한 바에 따름</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="clause__group">
                                <p class="clause__group--info agree__table--notice"><span> 주식회사 비드톡톡과 정보이용자(“행”)는 표지 및 별첨 계약내용에 따라 본 “AI멤버십 서비스 동의 계약”을 체결하고 신의에 따라 성실하게 계약상 의무를 이행할 것을 확약하였으며 이 계약의 증거로서 내용 확인 후 동의함으로서 계약 성립은 유효하다.</span></p>
                            </div>
                            <div class="clause__group clause__charge agree__cost">
                                <h2 class="clause__group--tit clause__charge--subject">※ 분석가에 따른 성공보수료</h2>
                                <div class="agree__table--wrap">
                                    <p class="clause__spanst--item clause__standard--subject">
                                        <span class="clause__list--title clause__standard--title">AI멤버십 동의 후 <i class="unline">이용 대금을 납부</i>한 경우 아래와 같은 성공 보수료율 적용</span>
                                    </p>
                                    <ul class="agree__table--group">
                                        <li class="agree__table--item content">
                                            <div class="agree__table--box">
                                                <div class="agree__table--element head">
                                                    <span class="agree__txt agree__txt--head">AI분석 펭, 도그, 수리</span>
                                                </div>
                                                <div class="agree__table--element body">
                                                    <span class="agree__txt agree__txt--head">없음</span>
                                                </div>
                                            </div>
                                            <div class="agree__table--box">
                                                <div class="agree__table--element head">
                                                    <span class="agree__txt agree__txt--head">분석기업<br>진비드, 인비드, 청비드, 솔비드, 비드플러스, 인비드</span>
                                                </div>
                                                <div class="agree__table--element body vm">
                                                    <p class="agree__txt agree__table--sub agree__table--title">낙찰가의 2% (부가세 포함)</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <p class="clause__group--info"><span>
                                    ※ AI분석으로 낙찰할 경우 성공보수료 없으며, 분석기업으로 낙찰할 경우 낙찰가의 2% 성공보수료율이 적용됩니다<br/>
                                    ※ 분석기업으로 낙찰한 경우 성공보수료의 지급은 PG사(KG이니시스)를 통하지 않고, 비드톡톡 자체의 계좌를 통하여 입금이 이루어집니다.<br/>
                                    ※ AI멤버십 동의 후 이용 대금을 납부하지 않은 경우에는 [전자입찰 분석정보 제공계약서]의 계약 내용에 따릅니다.</span>
                                </p>

                                <div class="clause__group">
                                    <p class="clause__group--info agree__table--notice">
                                        <span>&lt;표1&gt;</span>
                                    </p>
                                </div>
<!--                                <div class="agree__table&#45;&#45;wrap">-->
<!--                                    <p class="clause__spanst&#45;&#45;item clause__standard&#45;&#45;subject">-->
<!--                                        <span class="clause__list&#45;&#45;title clause__standard&#45;&#45;title">AI멤버십 동의 후 <i class="unline">이용 대금을 납부하지 않은 경우에는</i> [전자입찰 분석정보 제공계약서]의 계약 내용에 따른다</span>-->
<!--                                    </p>-->
<!--                                    <ul class="agree__table&#45;&#45;group">-->
<!--                                        <li class="agree__table&#45;&#45;item content">-->
<!--&lt;!&ndash;                                            <div class="agree__table&#45;&#45;box">&ndash;&gt;-->
<!--&lt;!&ndash;                                                <div class="agree__table&#45;&#45;element head">&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <span class="agree__txt agree__txt&#45;&#45;head">AI분석 펭, 도그, 수리</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                <div class="agree__table&#45;&#45;element body">&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <span class="agree__txt agree__txt&#45;&#45;head">낙찰가의 2.5% (부가세 포함)</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                            </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                            <div class="agree__table&#45;&#45;box">&ndash;&gt;-->
<!--&lt;!&ndash;                                                <div class="agree__table&#45;&#45;element head">&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <span class="agree__txt agree__txt&#45;&#45;head">분석기업<br>진비드, 인비드, 청비드, 솔비드, 비드플러스, 인비드</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                                <div class="agree__table&#45;&#45;element body vm">&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <p class="agree__txt agree__table&#45;&#45;sub">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <span class="agree__table&#45;&#45;title">낙찰가의 2% (부가세 포함)</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        * 분석기업 성공 보수료 지급은 낙찰일로부터 14일 이내 (별첨 계약내용 참조)&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </p>&ndash;&gt;-->
<!--&lt;!&ndash;                                                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                                            </div>&ndash;&gt;-->
<!--                                        </li>-->
<!--                                    </ul>-->
<!--                                </div>-->
<!--                                <p class="clause__group&#45;&#45;info"><span>&lt;표2&gt;</span></p>-->
                            </div>
                            <div class="clause__group agree__company">
                                <h2 class="clause__group--tit align-c auto-input">{{currentDate()}}</h2>
                                <p class="clause__group--info agree__company--announce"><span>※ 주식회사 비드톡톡 임직원들은 [AI멤버십 서비스 동의 계약]에 명시된 계약금 및 성공보수료
                                    이외에 어떠한 금품, 향응, 선물 그리고 편의를 수수하거나 제공을 요청하지 않으며 이를 위배한 임직원은 사규에 따라 징계합니다.</span></p>
                                <div class="comppany-info__group agree__company--info">
                                    <p class="clause__group--info align-c"><span>대전광역시 서구 계룡로553번길 18, 조이빌딩 9층</span></p>
                                    <p class="clause__group--info align-c"><span>주식회사 비드톡톡 </span></p>
                                    <p class="clause__group--info align-c"><span>TEL 1670-0508</span><span>FAX 042-367-3313</span></p>
                                </div>
                            </div>
                            <h2 class="menu__title clause__txt--title add-message">계 약 내 용</h2>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제 1조 (계약의 목적)</h2>
                                <p class="clause__group--info"><span>본 계약은 “동”이 “행”에게 전자 입찰 분석 정보를 제공하고 “행”은 “동”이 제공하는 전자 입찰 분석 정보를 이용하며 그 대가를
                                    “동”에게 지급하는 것과 관련하여 필요한 사항을 정함을 목적으로 한다.</span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제2조 (“동”의 의무)</h2>
                                <p class="clause__group--info"><span>본 계약에 따라 “동”은 “동”이 운영하는 인터넷 웹페이지(www.bidtok.co.kr)와 어플리케이션(비드톡톡)을 통하여 국가나
                                    지방자치단체가 공고한 각종 입찰 관련 정보를 “행”에게 제공하기로 한다. 또한 “행”이 “동”에게 요청하는 입찰건에 대해서도 동일하게 정보를 제공하기로 한다. “동”이 “행”에게 제공하는
                                    정보의 종류와 범위는 “동”의 웹페이지와 어플리케이션을 통해 제공되고 있는 내용을 기본으로 하며 유선, 문자, SNS, 이메일, 팩스 등도 포함된다.</span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제3조 (“행”의 의무) </h2>
                                <p class="clause__group--info"><span class="clause__group--info indent"><span>① “행”은 “동”으로부터 제2조의 정보를 제공 받는데에 대한
                                        대가로 제4조 ①항에 정한 액수에 따라 “동”에게 이용대금을 지급하여야 한다. 계약 금액이 지급된 후부터 분석실을 통한 가격 제시가 시작되는 것으로 한다.</span><span>②
                                        “행”은 본 계약 체결일로부터 분석기업(진비드, 인비드, 비드인, 청비드, 솔비드, 비드플러스를 칭하며 이하 “분석기업”으로 정한다)으로 분석요청한 입찰 공고에 대하여 분석실을 통하여 제시 가격을 확인하며 확인한 가격에 대하여 가격 이행을 하여야 한다. 단, 가격 불이행을 했을 경우
                                        횟수에 따라 “동”은 “행”에 대해 등급을 변동 할 수 있다. (단, 가격 불이행 가격이 낙찰가가 된 경우에는 횟수에 관계없이 즉시 등급을 변동할 수 있다) (비드톡톡 이용약관
                                        제10조 참조)</span></span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제4조 (이용 대금) </h2>
                                <p class="clause__group--info"><span class="clause__group--info indent"><span>① “행”은 본 계약 체결과 동시에 “동”에게 본 계약서
                                        표지에 정한 “계약 금액”을 지급한다. “동”의 귀책사유로 “행”에 대한 정보 제공이 불가능해진 경우를 제외하고 “행”은 “동”에게 기본 계약 금액의 반환을 청구할 수
                                        없다.</span><span>② “행”이 “동”으로부터 분석기업에게 분석요청하여 제시 받은 가격으로 입찰에 참가하여 최종 낙찰자로 선정 된 경우(“행”이 동가의 1순위가 된 후 추첨을 통하여 최종 낙찰이
                                        확정된 경우, “행”보다 선 순위의 입찰 참가자가 있음에도 불구하고 “행”이 최종 낙찰이 된 경우 등을 포함) “행”은 “동”에게 본 계약서 표지에 정한 “성공 보수료”를
                                        지급한다.단, 단가 계약(공사, 납품, 구매 설치, 용역 수행 등)의 경우 총 공사 예정 금액 또는 총 배정 예산으로 성공 보수료를 지급한다.</span><span>③ 공동 도급을
                                        진행하여(구성 업체,분담 업체) 최종 낙찰자가될 경우 최종 공사 비율에 따라 본 계약서 표지에 정한 성공 보수료를 지급한다. </span><span>④ “행”은 “동”의 웹페이지
                                        및 어플리케이션 상의 “분석실”을 통해 “분석제시가 확인” 버튼을 클릭한 공고 건에 관여하는 “행”이 해당 공고건의 입찰에 참여한 경우 “동”으로부터 제시 받은 가격으로 입찰에
                                        참여한 것으로 보아 ②항을 적용하기로 한다. </span><span>⑤ 분석기업으로 분석요청한 공고의 가격이행 여부의 표준 오차는 ±0.1% 범위로 인정하거나 또는 비드톡톡 가격 제시 기준 절상,절하 (ex
                                        만원단위, 십만단위, 백만단위 등) 일때 혹은 임의로 수정할 경우 또한 이행 여부 범위로 인정하여 성공 보수료를 지급한다. (분석서비스 이용약관 제13조 참조)
                                    </span><span>⑥ “행”은 본 계약서 표지에 정한 “성공 보수료”를 “동”의 성공 낙찰 일로부터 14 일 이내에 지급하여야 하며 지체하는 경우 “동”은 “행”에 대한 정보 제공을
                                        중단할 수 있다. 다만 성공 보수 금액이 1,000만원 이상일 경우 “행”은 성공 보수 금액 중 50%의 지급 유예를 신청할 수 있으며, “동”은 “행”의 지불 능력 기타 제반
                                        사정을 참작하여 지급 유예를 승인할 수 있다.(단,지급 유예 기간은 최장 60일 이내로 한다.)</span></span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제5조 (환불 규정) </h2>
                                <p class="clause__group--info">
                                    <span class="clause__group--info indent">
                                        <span>① “행”이 계약 체결 및 입금이 확인된 이후 “행”의 개인사정으로 인해 계약 해지를 요청한 경우 아래의 환불 규정에 따르며, 환불에 대한 수수료는 회원 개인이 부담합니다.</span>
                                        <span>단, 해지의 효력은 당 사이트 운영자에게 표시한 해지의 의사가 효력이 발하는 익일로 일할 계산됩니다.</span>
<!--                                        <span>② AI멤버십 상품별 이용기간을 부여받은 후 해지 의사를 표현했을 때 환불 금액 계산은 익일 기준으로 일할 계산됩니다.</span>-->
                                        <br/>
                                        <span>A 총 결제 금액의 10%</span>
                                        <span>B 결제한 멤버십 이용대금 ÷ 30일 / 180일 / 360일 (소수점 이하 단위는 반올림)</span>
                                        <span>C 해지 의사를 표현한 날짜까지의 사용일 수</span>
                                        <br/>
                                        <span>환불금액 = 결제금액 - A - (B × C)</span>
                                        <br/>
                                        <span>② 환불 금액은 요청 후 7일 이내로 입금됩니다.</span>
                                    </span>
                                </p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제6조 (비밀 보장) </h2>
                                <p class="clause__group--info"><span class="clause__group--info indent"><span>① “행”은 본 계약에 따라 “동”으로부터 제시 받은 가격을
                                        제3자에게 공개하거나 양도 할 수 없으며 이를 위반함으로 인해 “동” 이 입은 손해를 배상해야 한다. 또한 “행”은 본 계약에 의하여 지득한 “동”의 자료 및 정보 등의 비밀
                                        (이미 일반에 알려진 정보는 제외)을 타인에게 누설 또는 공개 할 수 없으며 이를 위반함으로 인해 “동” 이 입은 손해를 배상해야 한다.</span></span></p>
                            </div>
                            <div class="clause__group">
                                <h2 class="clause__group--tit">제7조 (분쟁의 해결) </h2>
                                <p class="clause__group--info"><span>본 계약과 관련하여 발생하는 분쟁은 수원지방법원 성남지원(택일 결정 필요)을 제1심 관할법원으로 하여 해결한다.</span></p>
                            </div>
                            <div class="agree__check--area">
                                <div class="agree__check--group pc">
                                    <div class="agree__check--box content clause__group--info"><span>“행”은 본 계약서 내용을 반드시 숙지하고 동의하며 원만히 계약이 성사되었음을
                                        인정합니다. 위 계약 내용에 대해 동의 하십니까?</span></div>
                                    <div class="agree__check--box company clause__group--info"><span>회사명:&nbsp;{{$parent.custBiz.cmpNm}}</span><span></span></div>
                                    <div class="agree__check--box agree clause__group--info"><span>동의</span><input type="checkbox"
                                                                                                                   id="check2" :checked="subscriptionFlag" @click="subscriptionFlag = !subscriptionFlag"><label for="check2"></label></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input popup__button--wrap use-consent__wrap--button single">
                    <button id="subscriptionBtnComn" type="submit" class="btn-basic btn__non-active" @click="closeSubscriptionPop(true)"><p class="txt__empha">동의</p>*약관을 끝까지 보신 후, 동의 가능합니다.</button>
                </div>
            </div>
        </div>
        <!-- /구독 서비스 제공 계약서 -->

        <!-- ai 멤버십 프로모션 팝업 -->
        <div class="popup__wrap advertise ai-promo not-view" :class="aiPromoClosePop ? 'active' : ''">
            <a class="new-year__container" href="javascript:void(0)"></a>
            <div class="popup__item--container">
                <a class="popup__item--group" href="javascript:void(0)" @click="aiPromoPopCookie(); $router.push('/membership');">
                </a>
                <a href="javascript:void(0)" class="not-view--chk" @click.prevent="aiPromoPopCookieYN = !aiPromoPopCookieYN">
                    <div class="input--check">
                        <input type="checkbox" id="check" :checked="aiPromoPopCookieYN">
                        <label for="check">
                            <span>3일 동안 보지않기</span>
                        </label>
                    </div>
                </a>
                <div class="popup__button--wrap pair">
                    <a href="javascript:void(0)" class="btn-basic btn-line-cb popup__close" @click="aiPromoPopCookie()">닫기</a>
                    <a href="javascript:void(0)" class="btn-basic btn-cta" @click="aiPromoPopCookie(); $router.push('/membership');" >자세히보기</a>
                </div>
            </div>
        </div>
        <!-- /ai 멤버십 프로모션 팝업 -->

        <!-- 마이페이지 이동 팝업 팝업 -->
        <div class="popup__wrap off-auto-anal" :class="isPopGoMyPage? 'active' : ''">
            <div class="popup__item--container">
                <div class="popup__item--group">
                    <div class="popup__item--head">
                        <!-- 체크 이미지 있을 경우 -->
                        <!--                        <div class="popup__img"></div>-->
                        <!-- 타이틀이 있을경우 -->
                        <h3 class="txt__title">분석서비스 이용 동의</h3>
                    </div>
                    <div class="popup__item--body">
                        <p class="txt__sub">AI멤버십을 사용하려면<br/>분석서비스 이용 동의가 필요합니다.</p>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="javascript:void(0)" class="btn-basic btn-line-cb" @click="isPopGoMyPage = false;">닫기</a>
                    <a href="javascript:void(0)" class="btn-basic btn-cta" @click="isPopGoMyPage = false; $goMenu('myPage')">마이페이지 이동</a>
                </div>
            </div>
            <!--     // popup-->
        </div>
        <!--  /분석실 이동 성공 팝업  active 추가 -->

        <!-- 비로그인 > 분석요청시 로그인 요청 팝업 -->
        <div class="popup__wrap confim login-pop entire-img-pop" :class="isPopLogin ? 'active' : ''">
            <div class="popup__item--container">
                <div class="popup__item--group">
                    <div class="popup__item--head">
                        <div class="popup__img"></div>
                    </div>
                    <div class="popup__item--body">
                        <p class="txt__title">로그인이 필요해요.</p>
                        <p class="txt__sub">로그인 후, 이용 가능한 기능입니다.
                            <br>로그인하고 이용해보시겠어요?
                        </p>
                    </div>
                </div>
                <div class="popup__button--wrap pair">
                    <a href="javascript:void(0)" class="btn-basic btn-line-cb popup__close" @click="isPopLogin = false">둘러보기</a>
                    <a href="javascript:void(0)" class="btn-basic btn-cta" @click="isPopLogin = false;  $goMenu('login')">로그인</a>
                </div>
            </div>
        </div>
        <!-- /비로그인 > 분석요청시 로그인 요청 팝업 -->

        <!-- AI멤버십 선택 팝업 -->
        <div class="popup__wrap off-auto-anal" :class="isPopSelAI ? 'active' : ''">
            <div class="popup__item--container">
                <div class="popup__item--group">
                    <div class="popup__item--head">
                        <!-- 체크 이미지 있을 경우 -->
                        <!--                        <div class="popup__img"></div>-->
                        <!-- 타이틀이 있을경우 -->
                        <h3 class="txt__title">AI멤버십</h3>
                    </div>
                    <div class="popup__item--body">
                        <p class="txt__sub">AI멤버십을 사용하려면<br/>상품을 선택해주세요.</p>
                    </div>
                </div>
                <div class="popup__button--wrap single">
                    <a href="javascript:void(0)" class="btn-basic btn-cta" @click="isPopSelAI = false;">닫기</a>
                </div>
            </div>
            <!--     // popup-->
        </div>
        <!--  /AI멤버십 선택 팝업 -->

        <!-- 2024 추석 팝업 -->
<!--        <div class="popup__wrap advertise chusuk-2024" :class="chuseokClosePop ? 'active' : ''">-->
<!--          <div class="popup__item&#45;&#45;container">-->
<!--            <a class="popup__item&#45;&#45;group" href="javascript:void(0)">-->
<!--            </a>-->
<!--            <div class="popup__button&#45;&#45;wrap pair">-->
<!--              <a href="javascript:void(0)" class="btn-basic btn-line-cb popup__close" @click="popCookie('chuseokCookie','3d', function(){ chuseokClosePop = false; })">오늘 하루 보지 않기</a>-->
<!--              <a href="javascript:void(0)" class="btn-basic btn-cta popup__close" @click="chuseokClosePop = false;">확인</a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <!-- /2024 추석 팝업 -->
    </div>
</template>

<script>
    import * as _ from 'lodash';

    export default {
        name: 'ComnCompnt',
        data: () => ({
            isPopUseAgree: true,
            isPopCustCntrct: false,
            isPopCustCntrctStep1: false,
            isPopCustCntrctStep2: false,
            isPopCustCntrctStep3: false,
            isPopCustCntrctStep2_1: false,
            isPopCustCntrctStep3_1: false,
            isPopSubscription: false,   //구독서비스 제공 계약서
            isPopGoMyPage: false,
            isPopLogin: false,          //로그인유도 팝업
            isPopSelAI: false,          //AI멤버십 선택하라는 팝업
            subscriptionYn: false,
            subscriptionFlag: false,
            custCntrct: {},

            aiPromoPopCookieYN: false,
            aiPromoClosePop: false, // AI멤버십 홍보 팝업

            // chuseokClosePop: false, // 2024 추석 팝업
        }),
        async mounted() {

            /* ai멤버십 팝업 세팅 */
            if (this.$cookies.get('aiPromoCookie') != 'Y') {
                const currentDate = new Date();
                const startDate = new Date('2024-08-07 00:00:00');
                const endDate = new Date('2024-09-01 00:00:00');

                if (currentDate >= startDate && currentDate < endDate) {
                    this.aiPromoClosePop = true;
                }
            }

            /* 추석 팝업 세팅 */
            // if (this.$cookies.get('chuseokCookie') != 'Y') {
            //     const currentDate = new Date();
            //     const startDate = new Date('2024-09-13 00:00:00');
            //     const endDate = new Date('2024-09-19 00:00:00');
            //
            //     if (currentDate >= startDate && currentDate < endDate) {
            //         this.chuseokClosePop = true;
            //     }
            // }

            this.initAiSub(0);
        },
        methods: {
            initAiSub(type) { // type => 0:닫기, 1:결제
                var self = this;
                this.$parent.getCustInfoComn(function(){
                    // 계약서 정보
                    self.$parent.getCustCntrctListComn(function(ccList) {
                        var custCntrctList = ccList;
                        custCntrctList = _.sortBy(custCntrctList, 'anlsAgreeDt').reverse();
                        if(custCntrctList.length > 0) {
                            self.custCntrct = _.filter(custCntrctList,function(o) {
                                return o.cntrctType == 'fee';
                            })[0];
                            //self.custCntrct = custCntrctList[0];

                            if (type == 1) // 완료 후 (결제팝업)
                                self.$parent.comnPopup( '/pay/pcPay/' + self.$parent.loginInfo.rcptmnId, 875, 780);
                        }
                    });
                });
            },
            /** AI멤버십 계약 동의 */
            insertAISubAgree(){

                if(!this.subscriptionYn) {
                    this.$toast.error("약관에 동의해 주세요.");
                    return;
                }

                //this.insertAISubCntrct('','');
                this.getAISubCntrctTmple();

            },
            /** AI멤버십 템플릿 가져오기 */
            getAISubCntrctTmple() {
                var param = {};

                this.$post('/api/cust/custCntrctTmpl',param)
                        .then(res => {
                            if(res.status == 'OK'){

                                var custCntrctTmpl= _.filter(res.custCntrctTmplList,function(o) {
                                    return o.id == 2;
                                })[0];
                                var custtmplHtml = custCntrctTmpl.cntrctTmplHtml;
                                var today = this.$moment();
                                var currentDt = today.format('YYYY년 MM월 DD일');
                                var subApplyDt = today.format('YYYY-MM-DD HH:mm:ss');

                                custtmplHtml = custtmplHtml.replaceAll('#{CMP_NM}', this.$parent.loginInfo.cmpNm);
                                custtmplHtml = custtmplHtml.replaceAll('#{USER_NM}', this.$parent.loginInfo.userNm);
                                custtmplHtml = custtmplHtml.replaceAll('#{subApplyDt}', subApplyDt);
                                custtmplHtml = custtmplHtml.replaceAll('#{currentDate}',  currentDt);
                                //custtmplHtml = custtmplHtml.replaceAll('#{nextYearDate}', today.add(1, 'year').subtract(1,'days').format('YYYY년 MM월 DD일'));
                                custtmplHtml = custtmplHtml.replaceAll('#{todayTime}', today.format('HH:mm'));

                                this.insertAISubCntrct(custCntrctTmpl, custtmplHtml, subApplyDt);

                            } else {
                                this.$toast.error('계약서 등록 실패');
                            }
                        })
                        .catch(error => console.log(error));
            },
            /** AI멤버십 체크 클릭시 */
            clkSubscription() {
                // if (this.cntrctAgreeYn != true) {
                //     this.isPopSubscription = false;
                //     this.$toast.error('분석서비스 제공계약서에 동의 후 확인 가능합니다.');
                // } else {
                if (this.$parent.loginInfo.subStatusCd != 1) {
                    this.isPopSubscription = true;
                    this.$parent.styleFuncComn(0);
                }
                //}
            },
            //AI멤버십 계약서 저장
            insertAISubCntrct(custCntrctTmpl, custtmplHtml, subApplyDt){
                var param = {
                    "custBizId": this.$parent.loginInfo.custBizList[0].id,
                    "custCntrctId": this.custCntrct.id,
                    "planId": this.$parent.selectedPlanId,
                    "planNm": this.$parent.selectedPlanId == "2" ? '3개월' : this.$parent.selectedPlanId == "3" ? '6개월' : this.$parent.selectedPlanId == "5" ? '12개월' : '',
                    "planPrice": this.$parent.selectedPlanId == "2" ? '399,000' : this.$parent.selectedPlanId == "3" ? '599,000' : this.$parent.selectedPlanId == "5" ? '990,000' : '',
                    "cntrctTmplId": custCntrctTmpl.id,
                    "cntrctHtml": custtmplHtml,
                    "lastAmndr": this.$parent.loginInfo.cmpNm,
                    "custTelno": this.$parent.loginInfo.custTelno,
                    "subApplyDt": subApplyDt,
                };

                // 분석동의 직후 페이지 갱신이 안된채로 AI멤버십 신청하는 경우 처리
                var self = this;
                if (param.custCntrctId == null) {
                    //alert("필수값이 없습니다.\n페이지를 새로고침 합니다..");
                    self.$parent.getCustCntrctListComn(function() {
                        param.custCntrctId = self.$parent.custCntrct.id;
                    });
                }

                if (this.subscriptionYn == true) {
                    this.$post('/api/cust/insertSubscription',param)
                        .then(res => {
                            if(res.status == 'OK'){
                                this.isPopCustCntrctStep2_1= false;
                                this.isPopCustCntrctStep3_1 = true;
                                this.$parent.getCustInfoComn(function(){
                                });
                            } else {
                                this.$toast.error('계약서 등록 실패');
                            }
                        })
                        .catch(error => console.log(error));
                }
            },
            //AI멤버십 동의창 닫기
            closeCustSubCntrctPop(){
                this.isPopSubscription = false;
                this.$parent.styleFuncComn(1);
            },
            currentDate() {
                const today = new Date();
                const year = today.getFullYear();
                const month = today.getMonth() + 1;
                const day = today.getDate();

                const formattedDate = `${year}년 ${month}월 ${day}일`;

                return formattedDate;
            },
            nextYearDate(){
                const today = new Date();
                const year = today.getFullYear() + 1;
                const month = today.getMonth() + 1;
                const day = today.getDate() - 1;

                const formattedDate = `${year}년 ${month}월 ${day}일`;

                return formattedDate;
            },
            closeSubscriptionPop(value) {
                if(value){
                    const subscriptionBtn = document.getElementById('subscriptionBtnComn');

                    if (subscriptionBtn.classList.contains('btn__active')) {
                        this.subscriptionYn = true;
                        this.subscriptionFlag = true;
                        //this.anlsAgreeFlag = true;
                        //this.isPopCntrct = false;
                        this.isPopSubscription = false;
                        this.$parent.styleFuncComn(1);
                    } else {
                        this.$toast.error("약관을 끝까지 보신 후, 동의 가능합니다.");
                    }
                    // if (this.anlsAgreeFlag) {
                    //     this.cntrctAgreeYn = true;
                    //     this.isPopCntrct = false;
                    // } else {
                    //     this.$toast.error("약관을 끝까지 보신 후, 동의 가능합니다.");
                    // }
                } else {
                    this.isPopSubscription = false;
                }
                // this.$refs.agree.focus();
            },
            activeScrollEvent(){
                if(!this.active){
                    this.active = true;
                    // const useAgreeScroll = document.getElementById('useAgreeScroll');
                    //
                    // useAgreeScroll.addEventListener('scroll', function(){
                    //     let popScrollHeight = useAgreeScroll.scrollHeight; // 스크롤 전체 높이
                    //     let popScrollTop = useAgreeScroll.scrollTop; // 스크롤 현재 높이
                    //     if (useAgreeScroll.getBoundingClientRect().height + popScrollTop > popScrollHeight - 3) {
                    //         const anlsAgreeBtn = document.getElementById('anlsAgreeBtn');
                    //         anlsAgreeBtn.classList.remove('btn__non-active');
                    //         anlsAgreeBtn.classList.add('btn__active');
                    //     }
                    // });
                    //
                    // // /* 개인정보 스크롤 이벤트*/
                    // const etcAgreeScroll = document.getElementById('etcAgreeScroll');

                    // etcAgreeScroll.addEventListener('scroll', function(){
                    //     let popScrollHeight = etcAgreeScroll.scrollHeight; // 스크롤 전체 높이
                    //     let popScrollTop = etcAgreeScroll.scrollTop; // 스크롤 현재 높이
                    //     if (etcAgreeScroll.getBoundingClientRect().height + popScrollTop > popScrollHeight - 3) {
                    //         const cntrctAgreeBtn = document.getElementById('cntrctAgreeBtn');
                    //         // cntrctAgreeBtn.disabled = false;
                    //         cntrctAgreeBtn.classList.remove('btn__non-active');
                    //         cntrctAgreeBtn.classList.add('btn__active');
                    //         //요기!!!!!!
                    //     }
                    // });

                    // /* 구독형 스크롤 */
                    const subscriptionScroll = document.getElementById('subscriptionScrollComn');

                    subscriptionScroll.addEventListener('scroll', function(){
                        let popScrollHeight = subscriptionScroll.scrollHeight; // 스크롤 전체 높이
                        let popScrollTop = subscriptionScroll.scrollTop; // 스크롤 현재 높이
                        if (subscriptionScroll.getBoundingClientRect().height + popScrollTop > popScrollHeight - 3) {
                            const subscriptionBtn = document.getElementById('subscriptionBtnComn');
                            // subscriptionBtn.disabled = false;
                            subscriptionBtn.classList.remove('btn__non-active');
                            subscriptionBtn.classList.add('btn__active');
                        }
                    });
                }
            },
            aiPromoPopCookie(){
                if (this.aiPromoPopCookieYN) {
                    document.querySelector('body').classList.remove('expand');
                    this.$cookies.set("aiPromoCookie",'Y',"3d");
                }
                this.aiPromoClosePop = false;
            },
            // 쿠키팝업 공통함수
            popCookie(cookieNm, day, callback){
                document.querySelector('body').classList.remove('expand');
                this.$cookies.set(cookieNm,'Y', day);

                callback();
            },
        }
    };
</script>
